// handles text and json response, replies on the content-type header for json responses.
export default async function (response) {
  let responseType = response.headers.get('content-type')
  let data = null;
  if (responseType && responseType.indexOf('application/json') > -1) {
    let t = await response.text();
    try {
      data = JSON.parse(t);
    }
    catch {
      data = t;
    }
  }
  else {
    data = await response.text();
  }
  if (response.ok) {
    if (data) return data;
    else return response;
  }
  else {
   //if ((response.status === 500) && !window.location.pathname.includes("signin")) window.location = `/signin`;
    if (data) throw data;
    else throw response;
  }
}
