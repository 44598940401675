import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetHoldings } from "../../services/portfolio";
import Context from "../../component/Context/Context";
import "./Portfolio.scss";
const Portfolio = (props) => {
  const history = useNavigate();
  const { pid } = useContext(Context);
  const [holdings, setHoldings] = useState([]);
  const getHoldings = async () => {
    if (pid) {
      const _holdings = await GetHoldings(pid);
      setHoldings(_holdings);
    }
  };

  useEffect(() => {
    (async () => {
      await getHoldings();
    })();
  }, [pid]);

  const handleClickManual = () => {
    history("/holding");
  };
  return (
    <>
      {" "}
      <ul class="nav nav-tabs justify-content-end">
        <li class="nav-item">
          <a class="nav-link active" href="#">
            Overview
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">
            Brokers
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">
            Reports
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">
            Settings
          </a>
        </li>
      </ul>
      <div className="porfolioonboard">
        {holdings.length > 0 && (
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">LSE</th>
                <th scope="col">Price</th>
                <th scope="col">Qty</th>
                <th scope="col">Value</th>
                <th scope="col">Capital Gains</th>
                <th scope="col">Dividends</th>
                <th scope="col">Currentcy Gain</th>
                <th scope="col">Return</th>
              </tr>
            </thead>
            <tbody>
              {holdings.map((holding, i) => {
                return (
                  <tr>
                    <td>{holding.instrumentMaster.symbl || ""}-{holding.cntry} {holding.instrumentMaster.nm}</td>
                    <td>{holding.instrumentTxnl.cmp}</td>
                    <td>{holding.qty}</td>
                    <td>{holding.qty * holding.instrumentTxnl.cmp}</td>
                    <td>{(holding.qty * holding.instrumentTxnl.cmp) - holding.cst}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>{(holding.qty * holding.instrumentTxnl.cmp) - holding.cst}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {holdings.length === 0 && (
          <>
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-12 mb-3">
                <div class="card">
                  <div
                    class="card-body text-center"
                    onClick={handleClickManual}
                  >
                    <img
                      src="https://dhln4w2iqv5o4.cloudfront.net/packs/assets/manually_add_trade-b77bf91cefc46a46c88d.svg"
                      alt="Manually add trades"
                      class="Componentsstyled__Logo-sc-l2u9rl-1 kgZrNd"
                    />
                    <h5 class="card-title">
                      Manually add Trades{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </h5>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Add trades or opening balances
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12 mb-3">
                <div class="card">
                  <div class="card-body text-center">
                    <img
                      src="https://dhln4w2iqv5o4.cloudfront.net/packs/assets/import_from_broker-c21d0df49f83c3d4f372.svg"
                      alt="Import from your broker"
                      class="Componentsstyled__Logo-sc-l2u9rl-1 kgZrNd"
                    />
                    <h5 class="card-title">
                      Import from your broker{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </h5>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Select from our list of supported services.
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12 mb-3">
                <div class="card">
                  <div class="card-body text-center">
                    <img
                      src="https://dhln4w2iqv5o4.cloudfront.net/packs/assets/import_file-7890d4610852c225a0a9.svg"
                      alt="Upload a file"
                      class="Componentsstyled__Logo-sc-l2u9rl-1 kgZrNd"
                    />
                    <h5 class="card-title">
                      Upload a file{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </h5>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Upload a CSV of trades or opening balances
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Portfolio;
