import fetchResponseHandler from '../helpers/fetchResponseHandler'

const GetPortfolio = async () => {
    try {
  
      var requestOptions = {
        method: "GET",
        headers: {
          "api-interaction-id":"",
          "x-session-id":"",
          "lang":""
        }
      };
  
      let response = fetch(
        "/portfolio-mgr/api/portfolios",
        requestOptions
      )
        .then((response) => {
          return fetchResponseHandler(response);
        })
        .catch((error) => {
          throw error;
        });
  
      return response;
    } catch (e) {
      throw e;
    }
};

const GetHoldings = (pid) =>{
    try{
    var requestOptions = {
        method: "GET",
        headers: {
          "api-interaction-id":"",
          "x-session-id":"",
          "lang":""
        }
      };
  
      let response = fetch(
        `/portfolio-mgr/api/holdings?portfolioId=${pid}`,
        requestOptions
      )
        .then((response) => {
          return fetchResponseHandler(response);
        })
        .catch((error) => {
          throw error;
        });
  
      return response;
    } catch (e) {
      throw e;
    }
}
  
  export { GetPortfolio,GetHoldings };
  