import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRouteWithLayout from "./component/PrivateRouteLayout";
import MainLayout from "./component/Layout/Main";
import { Dashboard, AddHolding, AddPortfolio, Portfolio ,Landing} from "./Views";
import SignIn from "./Views/SignIn/SignIn";

const RouteItems = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
            <Landing />
        }
      />
      <Route
        path="/dashboard"
        element={
          <MainLayout>
            <Dashboard />
          </MainLayout>
        }
      />
      <Route
        path="/portfolio"
        element={
          <MainLayout>
            <AddPortfolio />
          </MainLayout>
        }
      />
      <Route
        path="/portfolio/onboard"
        element={
          <MainLayout>
            <Portfolio />
          </MainLayout>
        }
      />
      <Route
        path="/holding"
        to
        element={
          <MainLayout>
            <AddHolding />
          </MainLayout>
        }
      />
      <Route
        path="/signin"
        exact
        element={
          <MainLayout>
            <SignIn />
          </MainLayout>
        }
      />
    </Routes>
  );
};

export default RouteItems;
