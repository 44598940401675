import React, { useState } from "react";
import "../../Portfolio/Portfolio.scss";
import { CreatePortfolio } from "../service";
import { useNavigate } from "react-router-dom";

const AddPortfolio = () => {
  const history = useNavigate();
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(false);
  const handleSave = async () => {
    try {
      await CreatePortfolio({ ...fields });
      history("/dashboard");
    } catch (e) {}
  };

  const handleChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };
  return (
    <div className="formportfolio">
      <div className="row mb-3">
        <div className="col-12 d-flex justify-content-end">
          <button
            onClick={handleSave}
            type="button"
            class="btn btn-sm save-btn"
          >
            Save & Continue
          </button>
        </div>
      </div>
      <div className="add-portfolio">
        <div className="row">
          <div className="col-md-6 col-lg-g col-sm-12">
            <div className="form-field">
              <label>Name</label>
              <input
                className="form-control"
                name="name"
                value={fields.name || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-g col-sm-12">
            <div className="form-field">
              <label>Residence Country</label>
              <select
                class="form-select"
                name="country"
                value={fields.country || ""}
                onChange={handleChange}
              >
                <option selected>Select country</option>
                <option value="IN">India</option>
                <option value="USA">United States of America</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 col-lg-g col-sm-12">
            <div className="form-field">
              <label>Currency</label>
              <select
                class="form-select"
                name="currency"
                value={fields.currency || ""}
                onChange={handleChange}
              >
                <option selected>Select currency</option>
                <option value="MF">INR</option>
                <option value="FIXED">USD</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 col-lg-g col-sm-12">
            <div className="form-field">
              <label>Type</label>
              <select
                class="form-select"
                name="type"
                value={fields.type || ""}
                onChange={handleChange}
              >
                <option selected>Select type of the portfolio</option>
                <option value="EQ">Equity</option>
                <option value="MF">Mutual Fund</option>
                <option value="FIXED">Fixed</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPortfolio;
