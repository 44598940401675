const CreatePortfolio = async (fields) => {
  try {
    var raw = JSON.stringify({
      currency: fields.currency,
      country: fields.country,
      name: fields.name,
      type: fields.type,
    });

    var requestOptions = {
      method: "POST",
      headers: {
        "api-interaction-id":"",
        "x-session-id":"",
        "lang":"",
        "Content-Type":"application/json"
      },
      body: raw,
    };

    let response = fetch(
      "/portfolio-mgr/api/portfolios",
      requestOptions
    )
      .then((response) => {
        return response.text();
      })
      .catch((error) => {
        throw error;
      });

    return response;
  } catch (e) {
    throw e;
  }
};

export { CreatePortfolio };
