import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Topbar } from "./components";
import "./Main.scss";

const Main = (props) => {
  const { children } = props;
  const location = useLocation();
  const { hash, pathname, search } = location;
  const isDesktop = window.innerWidth < 400 ? false : true;

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { isMobile: !isDesktop })
  );
  return (
    <div>
      <div class="main-wrapper d-flex">
        <div className="content">
          {pathname !== "/signin" && <Topbar />}
         <div className="main-container">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Main;
