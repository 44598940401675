import React, { useState, useContext } from "react";
import { SearchIntrument, SearchIntrumentById, addTrades } from "../service";
import Context from "../../../component/Context/Context";
import Autocomplete from "../../../component/InstrumentsAutoComplete/AutoComplete";
import getCurrencySymbol from "../../../helpers/getCurrencySymbol";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import "../Holding.scss";
import _ from "lodash";

const AddHolding = () => {
  const {pid} = useContext(Context);
  const [fields, setFields] = useState({
    pid: pid,
    ik: "",
    xchng: "",
    ctry: "",
    qty: "0",
    price: "0",
    ccy: "",
    trdDt: new Date(),
    fee: "0",
    cmp: "", //- cmp - 2
    typ: "",
    trdTyp: "BUY", //
    costBase: "0",
  });
  const [instruments, setInstruments] = useState([]);

  const [loading, setLoading] = useState(false);
  const handleSave = async () => {
    try {
      let trades = await addTrades({ ...fields });
    } catch (e) {}
  };

  const handleChangeInputInstrument = async (e) => {
    try {
      if (e) {
        let res = await SearchIntrument(e);
        setInstruments(res);
      } else {
        setInstruments([]);
      }
    } catch (e) {}
  };
  const [debounce] = useState((e) =>
    _.debounce(handleChangeInputInstrument, 1000)
  );

  const handleChange = (e) => {
    console.log(e);
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  const handleGetIntrumentById = async (ik) => {
    let res = await SearchIntrumentById(ik);
    return res;
  };
  const handleSelecteIntrumentValues = async (v) => {
    let res = await handleGetIntrumentById(v.ik);

    setFields({
      ...fields,
      ik: v.ik,
      xchng: v.exchg,
      ctry: v.ctry,
      ccy: v.ccy,
      typ: v.typ,
      symbl: v.symbl,
      pcls: res.pcls,
      trDt: res.trDt,
      cmp: res.cmp,
      nm: v.nm,
    });
  };

  return (
    <div className="formholding">
       <div className="row my-3">
        <div className="col-12 d-flex justify-content-end">
          <button type="button" class="btn btn-sm close-btn mx-2">
            Close
          </button>
          <button
            type="button"
            class="btn btn-sm save-btn"
            onClick={handleSave}
          >
            Save & Continue{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-send"
              viewBox="0 0 16 16"
            >
              <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
            </svg>
          </button>
        </div>
      </div>
      <div className="add-holding">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-sm-12 mb-2">
            <div className="form-field">
              <label>
                Search by instrument name{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-question-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                </svg>
              </label>
              <Autocomplete
                onInputChange={debounce}
                suggestions={instruments}
                selected={fields.in}
                setValue={(value) => handleSelecteIntrumentValues(value)}
              />
            </div>
          </div>
          {fields.trDt && fields.ccy && (
            <div className="col-md-6 col-lg-6 col-sm-12 mb-2 intrument-details">
              <div className="row mainrow">
                <div className="col-md-8 col-lg-8 col-sm-8">
                  {fields.symbl}.{fields.typ}
                </div>
                <div className="col-md-4 col-lg-4 col-sm-4 text-end">
                  {fields.ctry}
                  {getCurrencySymbol("en-US", fields.ccy)}
                  {fields.pcls}
                </div>
              </div>
              <div className="row subrow">
                <div className="col-md-8 col-lg-8 col-sm-8">{fields.nm}</div>
                <div className="col-md-4 col-lg-4 col-sm-4 text-end">
                  {moment(fields.trDt).format("MM-DD-YYYY")}
                </div>
              </div>
            </div>
          )}
          <div className="col-md-12 col-lg-12 col-sm-12 mb-2">
            <div className="form-field">
              <label>
                Trade Type{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-question-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                </svg>
              </label>
              <br />
              <div class="form-check form-check-inline">
                <input
                  checked={fields.trdTyp === "BUY"}
                  class="form-check-input"
                  type="radio"
                  name="trdTyp"
                  id="inlineRadio1"
                  value="BUY"
                  onChange={handleChange}
                />
                <label class="form-check-label" for="inlineRadio1">
                  Buy
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  checked={fields.trdTyp === "OPEN_BAL"}
                  class="form-check-input"
                  type="radio"
                  name="trdTyp"
                  id="inlineRadio2"
                  value="OPEN_BAL"
                  onChange={handleChange}
                />
                <label class="form-check-label" for="inlineRadio2">
                  Open Balance
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12 mb-2">
            <div className="form-field">
              <label>Trade Date</label>
              <div class="input-group mb-3">
                <DatePicker
                  disabled={!fields.ik}
                  className="form-control"
                  name="trdDt"
                  clearIcon={false}
                  calendarIcon={false}
                  value={fields.trdDt}
                  onChange={(date) =>
                    handleChange({ target: { name: "trdDt", value: date } })
                  }
                />
                <span class="input-group-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-calendar2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z" />
                    <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          {fields.trdTyp === "OPEN_BAL" && (
            <div className="col-md-4 col-lg-4 col-sm-12 mb-2">
              <div className="form-field">
                <label>Total Cost Base</label>
                <div class="input-group">
                  <input
                    disabled={!fields.ik}
                    type="number"
                    class="form-control"
                    name="costBase"
                    onChange={handleChange}
                  />
                  {fields.ccy && (
                    <span class="input-group-text">
                      {getCurrencySymbol("en-US", fields.ccy)}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="col-md-4 col-lg-4 col-sm-12 mb-2">
            <label>Quantity</label>
            <div class="input-group mb-3">
              <input
                disabled={!fields.ik}
                type="number"
                class="form-control"
                name="qty"
                onChange={handleChange}
              />
              <span class="input-group-text">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-0-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.988 12.158c-1.851 0-2.941-1.57-2.941-3.99V7.84c0-2.408 1.101-3.996 2.965-3.996 1.857 0 2.935 1.57 2.935 3.996v.328c0 2.408-1.101 3.99-2.959 3.99M8 4.951c-1.008 0-1.629 1.09-1.629 2.895v.31c0 1.81.627 2.895 1.629 2.895s1.623-1.09 1.623-2.895v-.31c0-1.8-.621-2.895-1.623-2.895" />
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8" />
                </svg>
              </span>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12 mb-2">
            <div className="form-field">
              <label>
                Price{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-question-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                </svg>
              </label>
              <div class="input-group">
                <input
                  disabled={!fields.ik}
                  type="number"
                  class="form-control"
                  name="price"
                  onChange={handleChange}
                />
                {fields.ccy && (
                  <span class="input-group-text">
                    {getCurrencySymbol("en-US", fields.ccy)}
                  </span>
                )}
              </div>
            </div>
          </div>
          {fields.trdTyp === "BUY" && (
            <div className="col-md-4 col-lg-4 col-sm-12 mb-2">
              <div className="form-field">
                <label>
                  Fee{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-question-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                  </svg>
                </label>
                <div class="input-group">
                  <input
                    disabled={!fields.ik}
                    type="number"
                    class="form-control"
                    name="fee"
                    onChange={handleChange}
                  />
                  {fields.ccy && (
                    <span class="input-group-text">
                      {getCurrencySymbol("en-US", fields.ccy)}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row justify-content-end">
          <div className="col-md-6 col-lg-4 col-sm-12 mb-2 total-details text-center">
            <div className="label">
              {fields.trdTyp === "BUY" ? "Total" : "Average cost per share"}
            </div>
            {fields.trdTyp === "BUY" && fields.price && fields.qty && fields.fee
              ? Number(fields.price) * Number(fields.qty) + Number(fields.fee)
              : fields.trdTyp === "OPEN_BAL" && fields.qty && fields.costBase
              ? Number(fields.costBase) / Number(fields.qty)
              : 0}
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default AddHolding;
