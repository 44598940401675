import React from "react";
import "./signin.scss";

const SignIn = () => {
  const handleClick = async () => {
    window.open(
      `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?response_type=code&client_id=379018314947-41dr8nd9qei5jojp904rbuv726d8svdt.apps.googleusercontent.com&state=offline&scope=openid&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}`,
      "_self"
    );
  };
  return (
    <>
      <div className="row signin-screen">
        <div className="col-6">
          <div className="product-logo">
            <img
              src="https://open-frontend-bucket.s3.amazonaws.com/logos/logo-type-purple.svg"
              alt=""
            />
          </div>

          <div className="login-form">
            <h2 className="header001">Sign in</h2>
            <p>Login to run your business together!</p>
            <div className="googlesignin001">
              <button
                onClick={() => handleClick()}
                type="button"
                className="login-with-google-btn"
              >
                Sign in with Google
              </button>
            </div>
          </div>
        </div>

        <div className="col-6 h-screen"></div>
      </div>
    </>
  );
};

export default SignIn;
