import React, { useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { GetToken } from "./service";
import { GetPortfolio, GetHoldings } from "../../services/portfolio";
import Context from "../../component/Context/Context";

const Dashboard = (props) => {
  const history = useNavigate();
  const { pid, setPID } = useContext(Context);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    (async () => {
      try {
        if (code) {
          let res = await GetToken(code);
        }
        let portfolio = await GetPortfolio();
        if (portfolio.length > 0) {
          setPID(portfolio[0].pid);
          history("/portfolio/onboard");
        } else {
          history("/portfolio");
          // props.history.push({pathname:'/portfolio'})
          // window.location.reload();
        }
      } catch (e) {
      }
    })();
  });

  return <></>;
};

export default Dashboard;
