import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import FetchInterceptor from "./helpers/requestInterceptors";
import Context from "./component/Context/Context";
// import { GetPortfolio } from "./services/portfolio";
// import getCookie from "./helpers/getCookie";
FetchInterceptor();
function App() {
  const [selectedPortfolio, setSelectedPortfolio] = useState("");
  const handleChangePortfolio = (pid) => {
    setSelectedPortfolio(pid);
  };

  return (
    <BrowserRouter>
      <Context.Provider
        value={{ pid: selectedPortfolio, setPID: handleChangePortfolio }}
      >
        <Routes />
      </Context.Provider>
    </BrowserRouter>
  );
}

export default App;
