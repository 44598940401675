import React, { useEffect, useState } from "react";
import "./autoComplete.scss";

const Autocomplete = (props) => {
  const [active, setActive] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");
  useEffect(() => {
    document.body.addEventListener("click", function (evt) {
      //note evt.target can be a nested element, not the body element, resulting in misfires
      console.log(evt.target);
      if (!evt.target.closest(".autocomplete-control")) {
        setIsShow(false);
      }
    });
  }, []);
  const onChange = async (e) => {
    setInput(e.target.value);
    setIsShow(true);
    try {
      setLoading(true);
      await props.onInputChange(e.target.value);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  const onClickOption = (e) => {
    props.setValue(e);
    setIsShow(false);
  };

  const onClick = () => {
    if (props.suggestions.length > 0) {
      setIsShow(true);
    }
  };
  const renderAutocomplete = () => {
    if (isShow && input) {
      if (props.suggestions.length) {
        return (
          <ul className="autocomplete">
            {props.suggestions.map((s, index) => {
              let className;
              if (s.ik === props.selected) {
                className = "active";
              }
              return (
                <li
                  className={className}
                  key={s.ik}
                  onClick={() => onClickOption(s)}
                >
                  <div>
                    <span className="ik">
                      {s.symbl || s.exchg} : {s.ctry}
                    </span>
                    <br />
                    <span className="isin">{s.isin}</span>
                    <br />
                    <span className="nm">{s.nm}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        );
      } else {
        return (
          <div className="no-autocomplete">
            <em>{loading ? 'Loading..': 'No match found'}</em>
          </div>
        );
      }
    }
    return <></>;
  };
  return (
    <div className="autocomplete-control">
      <input
        className="form-control w-100 form-control-lg"
        type="text"
        onChange={onChange}
        onClick={onClick}
        value={input}
      />
      {renderAutocomplete()}
    </div>
  );
};
export default Autocomplete;
