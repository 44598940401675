import fetchResponseHandler from "../../helpers/fetchResponseHandler";

const GetToken = async (code) => {
  var raw = JSON.stringify({
    code: code,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
  });

  var requestOptions = {
    method: "POST",
    headers: {
        "api-interaction-id":"",
        "x-session-id":"",
        "lang":"",
        "Content-Type": "application/json"
    },
    body: raw,
  };
  let res = await fetch(
    "/portfolio-mgr/api/auth/token",
    requestOptions
  )
    .then((response) => {
      return fetchResponseHandler(response);
    })
    .catch((error) => {
      throw error;
    });
    if (res.idToken) {
      document.cookie = `a=${res.idToken}`;
    }
  
  return res;
};

export { GetToken };
