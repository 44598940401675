import fetchResponseHandler from '../../helpers/fetchResponseHandler'

const SearchIntrument = async (text) => {
  var myHeaders = new Headers();
  myHeaders.append("api-interaction-id", "");
  myHeaders.append("x-session-id", "");
  myHeaders.append("lang", "");
  myHeaders.append("Accept", "*/*");

  var requestOptions = {
    method: "GET",
      headers: {
        "api-interaction-id":"",
        "x-session-id":"",
        "lang":""
      },
  };

  let res = await fetch(
    `/portfolio-mgr/api/instrument/search?text=${text}`,
    requestOptions
  )
    .then((response) => {
      return fetchResponseHandler(response);
    })
    .catch((error) => {
      throw error;
    });

  return res;
};

const SearchIntrumentById = async (text) => {
  var myHeaders = new Headers();
  myHeaders.append("api-interaction-id", "");
  myHeaders.append("x-session-id", "");
  myHeaders.append("lang", "");
  myHeaders.append("Accept", "*/*");

  var requestOptions = {
    method: "GET",
      headers: {
        "api-interaction-id":"",
        "x-session-id":"",
        "lang":""
      },
  };

  let res = await fetch(
    `/portfolio-mgr/api/instrument/${text}`,
    requestOptions
  )
    .then((response) => {
      return fetchResponseHandler(response);
    })
    .catch((error) => {
      throw error;
    });

  return res;
};

const addTrades = (fields) =>{
  try {
    var raw = JSON.stringify({
      "pid": fields.pid,
      "ik": fields.ik,
      "xchng": fields.xchng,
      "ctry": fields.ctry,
      "qty": fields.qty,
      "price": fields.price,
      "ccy": fields.ccy,
      "trdDt": fields.trdDt,
      "fee": fields.fee,
      "cmp": fields.cmp,
      "typ": fields.typ,
      "trdTyp": fields.trdTyp,
      "costBase": fields.costBase
    });

    var requestOptions = {
      method: "POST",
      headers: {
        "api-interaction-id":"",
        "x-session-id":"",
        "lang":"",
        "Content-Type":"application/json"
      },
      body: raw,
    };

    let response = fetch(
      "/portfolio-mgr/api/trades",
      requestOptions
    )
      .then((response) => {
        return response.text();
      })
      .catch((error) => {
        throw error;
      });

    return response;
  } catch (e) {
    throw e;
  }
}

export { SearchIntrument ,SearchIntrumentById,addTrades};
