/**
 * Assigns the directive - credentials: same-site - to every fetch request
 * Makes sure cookies are not being sent to cross domain requests
 * Makes sure all cookies are sent with every request.
 */
import getCookie from "./getCookie";

export default function () {
  let realFetch = window.fetch;
  window.fetch = function (url, opts) {
    //
    let accessToken = getCookie("a");
    // normal prod build
    if (accessToken) {
      opts.headers["Authorization"] = "Bearer " + accessToken;
    }
    let updated = Object.assign({}, { credentials: "same-origin" }, opts);
    url = `${process.env.REACT_APP_API_URL || ''}${url}`;
    // let updated = Object.assign({}, { credentials: 'include' }, opts)
    // let updated = Object.assign({}, opts)
      // if (opts?.dodgeCors && opts?.dodgeCors === true) {
      //   opts.headers['x-resource-url'] = url;
      //   url = `${process.env.REACT_APP_BASE_URL || ''}/api/gateway`;
      // }
      // else if (url.includes('/auth/signin') || url.includes('/auth/signout')) {
      //   url = `${process.env.REACT_APP_BASE_URL || ''}${url}`;
      // }
      // else if (url.includes('/dms/')) {
      //   opts.mode = 'no-cors';
      //   url = `${process.env.REACT_APP_BASE_URL || ''}${url}`;
      // }
      // else if (url.startsWith('/')) {
      //   url = `${process.env.REACT_APP_BASE_URL || ''}/api/proxy${url}`;
      // }
    //}

    return realFetch(url, updated);
  };
}

