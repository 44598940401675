import React, { useEffect, useState, useContext } from "react";
import { GetPortfolio } from "../../../../../services/portfolio";
import Context from "../../../../Context/Context";

const Topbar = (props) => {
  const { setPID, pid } = useContext(Context);
  const [portfolio, setPortfolio] = useState([]);
  useEffect(() => {
    if (pid) {
      (async () => {
        try {
          let res = await GetPortfolio();
          setPortfolio(res);
        } catch (e) {}
      })();
    }
  }, [pid]);

  const handleClick = (pid) => {
    setPID(pid);
  };
  return (
    <>
      <div className="topbar">
        <nav class="navbar navbar-expand-lg navbar-light">
          <div class="container-fluid">
            <div class="navbar-current-page" href="#">
              Dashboard
            </div>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle portfolio-dropdown"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Choose Porfolio
                  </a>
                  <ul
                    class="dropdown-menu portfolio-dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <a class="dropdown-item" href="#">
                        View all portfolios
                      </a>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    {portfolio?.map((p, i) => {
                      return (
                        <li key={p.pid}>
                          <a
                            className={`${
                              pid === p.pid ? "selectedpid" : ""
                            } dropdown-item`}
                            onClick={() => handleClick(p.pid)}
                          >
                            {p.nm}
                          </a>
                        </li>
                      );
                    })}

                    {portfolio.length > 0 && (
                      <li>
                        <hr class="dropdown-divider" />
                      </li>
                    )}
                    <li>
                      <a class="dropdown-item" href="#">
                        Upgrade to add a portfolio
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              {/* <form class="d-flex">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form> */}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Topbar;
